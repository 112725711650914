<template>
    <div>
        <modal ref="fieldDetailsModal" class="field__detail__modal">
            <template v-slot:header>
                <h4>FieldDetails</h4>
            </template>

            <template v-slot:body>
                <form onsubmit="return false">
                    <table>
                        <thead>
                            <tr>
                                <td>Create at</td>
                                <td>Create by</td>
                            </tr>
                        </thead>
                        <tbody style="margin-top: 20px">
                            <tr>
                                <td>{{ fieldDetail.created_at }}</td>
                                <td>
                                    {{ fieldCreatedByMap[fieldDetail.created_by] }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </template>

            <template v-slot:footer>
                <div></div>
            </template>
        </modal>

        <modal ref="modalName">
            <template v-slot:header>
                <span class="close" style="font-size: 14px">Add Field</span>
            </template>

            <template v-slot:body>
                <form onsubmit="return false">
                    <div>
                        <p>
                            <label class="label">Field Type</label>
                        </p>
                        <multiselect v-model="fieldType" :options="fieldSelectOptions" placeholder="Select Field type *" class="multiselect-dropdown"> </multiselect>
                    </div>
                    <div>
                        <p>
                            <label class="label">Field Label</label>
                        </p>
                        <p v-if="!fieldLabel && submissionError">*Label can not be empty*</p>

                        <neo-input :changeCase="true" :shadow="false" :placeholder="fieldTypePlaceholder" borderFull="1px solid var(--brand-accent-neutral)" bg="white" margin="0.5rem 0" v-model="fieldLabel" :style="{width: '20vw'}"> </neo-input>

                        <p><label class="label">Description</label></p>

                        <neo-input :changeCase="true" :shadow="false" :placeholder="fieldDescriptionPlaceholder" borderFull="1px solid var(--brand-accent-neutral)" bg="white" margin="0.5rem 0" v-model="fieldDescription" :style="{width: '20vw'}"> </neo-input>

                        <div v-if="['select', 'radio', 'select_multi'].includes(fieldType)" class="options__wrapper">
                            <h1>options</h1>
                            <div v-for="index in dynamicOptions.length" :key="index" class="options__value__wrapper">
                                <neo-input :changeCase="true" :shadow="false" :placeholder="multipleOptionsPlaceholder" borderFull="1px solid var(--brand-accent-neutral)" bg="white" margin="0.5rem 0" v-model="dynamicOptions[index - 1]" :style="{width: '20vw'}"> </neo-input>

                                <neo-button @click="clearOptionValue(index - 1)" bg="#e2e2e2" color="white" hoverBg="#d6d4d4" hoverText="white" :style="{width: '2rem', height: '2rem'}" margin="1rem 0" icon="close" iconSize="0.6rem"></neo-button>
                            </div>
                            <p v-if="!canSave()" style="text-align: center">
                                *atleast one value should be non empty*
                            </p>

                            <neo-button @click="addOptionValue()" padding="1rem 3rem" margin="0 0.5rem" label="Add option" borderColor="var(--brand-accent-dark)" :shadow="true" bg="var(--brand-accent-dark)" color="var(--text-fade-color)" hoverBg="var(--brand-accent-hover-on-dark)" hoverText="var(--text-fade-color)"></neo-button>
                        </div>
                        <div style="margin-top: 50px">
                            <neo-button v-if="canSave()" class="save__field__button" label="Save" bg="var(--brand-color)" color="white" hoverBg="var(--brand-hover-on)" hoverText="white" :style="{width: '10rem'}" margin="1rem 0" @click="saveField()"></neo-button>
                        </div>
                    </div>
                </form>
            </template>

            <template v-slot:footer>
                <div></div>
            </template>
        </modal>

        <div v-if="filteredFields.length === 0" class="table" style="border-left: solid 2px var(--brand-color); border-radius: 10px">
            <div style="text-align: center">
                <neo-button label="Add custom field" color="var(--brand-color)" icon="add-blue" iconSize="0.7rem" hoverBg="var(--brand-transparent)" hoverText="var(--brand-color)" padding="0.5rem 1rem 0.5rem 0.5rem" margin="0.5rem 0" @click="$refs.modalName.openModal()"></neo-button>
                <span style="display: block; margin-top: 20px">Add some field to field master</span>
            </div>
        </div>

        <div v-if="filteredFields.length >= 1" class="table">
            <neo-input :changeCase="true" :shadow="false" :placeholder="searchFieldPlaceholder" borderFull="1px solid var(--brand-accent-neutral)" bg="white" margin="0.5rem 0" v-model="searchQuery" :style="{width: '20vw', height: '40px'}" @input="searchField()"> </neo-input>

            <div class="add__custom__field">
                <neo-button label="Add custom field" color="var(--brand-color)" icon="add-blue" iconSize="0.7rem" hoverBg="var(--brand-transparent)" hoverText="var(--brand-color)" padding="0.5rem 1rem 0.5rem 0.5rem" margin="0.5rem 0" @click="$refs.modalName.openModal()"></neo-button>
            </div>
            <p v-if="errorFieldDeletion">*This field is active in one of the cases, cant delete*</p>
            <div class="field__headers" style="color: black; font-size: medium">
                <div style="width: 280px">Field Name</div>
                <div style="width: 280px">Field type</div>
                <div>Action</div>
            </div>

            <div v-for="(field, index) in filteredFields" :key="index" class="field__wrapper">
                <div class="field__label" @click="showFieldDetails(field.field_id)">
                    {{ field.label }}
                    <div class="field__description">
                        {{ field.description }}
                    </div>
                </div>
                <div class="field__type">
                    <font-awesome-icon icon="sort-alpha-down" v-if="field.field_type == 'text'" />
                    <font-awesome-icon icon="sort-numeric-down" v-if="field.field_type == 'number'" />
                    <font-awesome-icon icon="check-square" v-if="field.field_type == 'checkbox'" />
                    <font-awesome-icon icon="globe-americas" v-if="field.field_type == 'url'" />
                    <font-awesome-icon icon="dot-circle" v-if="field.field_type == 'radio'" />
                    <font-awesome-icon icon="chevron-circle-down" v-if="field.field_type == 'select'" />
                    <font-awesome-icon icon="user-secret" v-if="field.field_type == 'select_multi'" />

                    <span class="field__type--typename">{{ field.field_type }}</span>
                </div>
                <div class="field__delete__icon">
                    <neo-button
                        @click="deleteField(field.field_id)"
                        bg="#e2e2e2"
                        color="white"
                        hoverBg="#d6d4d4"
                        hoverText="white"
                        :style="{
                            width: '1.2rem',
                            height: '1.2rem',
                            padding: '0px',
                        }"
                        icon="close"
                        iconSize="0.4rem"
                    ></neo-button>
                </div>
                <div></div>
            </div>

            <div class="pagination" v-if="showPaginationNavigation()">
                <span class="paginator__link" v-for="page in Math.ceil(fields.length / fieldsInonePage)" :key="page">
                    <a
                        href="#"
                        @click="paginate(page)"
                        :class="{
                            highlightPaginateNavigation: page === currentFieldPage,
                        }"
                        >{{ page }}</a
                    >
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import {caseApi, userApi} from "../../main.js";
import Button from "@/components/button";
import Modal from "./modal";
import Input from "@/components/input";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";

import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
    name: "fields",
    components: {
        "neo-button": Button,
        "neo-input": Input,
        "font-awesome-icon": FontAwesomeIcon,
        // "neo-dropdown": Dropdown,
        Modal,
        Multiselect,
    },
    data() {
        return {
            options: ["foo", "bar", "baz"],
            dynamicOptions: [],
            submissionError: false,
            dynamicOptionsCount: 0,
            showModal: false,
            fieldType: "",
            fieldLabel: "",
            selectedField: "",
            fieldTypePlaceholder: "Label",
            searchFieldPlaceholder: "search fields",
            multipleOptionsPlaceholder: "option value",
            fields: [],
            filteredFields: [],
            fieldSelectOptions: [],
            errorFieldDeletion: false,
            fieldDetail: {},
            searchQuery: "",
            fieldCreatedBy: null,
            fieldCreatedByMap: {},
            fieldDescription: "",
            fieldsInonePage: 10,
            currentFieldPage: 1,
            fieldDescriptionPlaceholder: "Field description",
            items: [
                {age: 40, first_name: "Dickerson", last_name: "Macdonald"},
                {age: 21, first_name: "Larsen", last_name: "Shaw"},
                {age: 89, first_name: "Geneva", last_name: "Wilson"},
                {age: 38, first_name: "Jami", last_name: "Carney"},
            ],
        };
    },

    async mounted() {
        await this.fetchFields();
        await this.fetchFieldTypes();
    },

    methods: {
        canSave() {
            if (["radio", "select", "select_multi"].includes(this.fieldType)) {
                return this.dynamicOptions.length >= 1 && !this.dynamicOptions.every((el) => el === "");
            } else {
                return true;
            }
        },
        clearOptionValue(index) {
            this.dynamicOptions.splice(index, 1);
        },
        addOptionValue() {
            this.dynamicOptions.push("");
        },

        async searchField() {
            //first search locally

            if (this.searchQuery.length == 0) {
                this.filteredFields = this.fields;
                this.paginate(1);
                return;
            }

            var items = this.fields.filter((el) => el.label.includes(this.searchQuery));
            var itemsMap = new Map();
            //ask server for the matching fields

            let response = caseApi.get(`/api/v1/fields/search?search_query=${this.searchQuery}`).then((resp) => {
                // if(items.length>=1 && response.data.length)

                items.push(...resp.data);
                if (items.length >= 1) {
                    items.forEach((item) => itemsMap.set(item.label, item));
                    this.filteredFields = [...itemsMap.values()];
                }
            });
        },

        async deleteField(id) {
            let response = caseApi
                .delete(`/api/v1/fields/${id}`)
                .then((response) => {
                    Promise.all([this.fetchFields()]);
                    this.errorFieldDeletion = false; // clear previous field deletion errors if successfull
                })
                .catch((err) => {
                    if (err.response.status == 400) {
                        this.errorFieldDeletion = true;
                    }
                });
        },

        async showFieldDetails(id) {
            let response = caseApi
                .get(`/api/v1/fields/${id}`)
                .then((resp) => {
                    this.fieldDetail = resp.data;
                    this.$refs.fieldDetailsModal.openModal();
                    Promise.all([this.fetchUserNameById(resp.data.created_by)]);
                })
                .catch((err) => {});
        },

        async fetchUserNameById(id) {
            let response = userApi
                .get(`/api/v1/accounts/${localStorage.getItem("account_id")}/users/${id}`)
                .then((resp) => {
                    this.fieldCreatedByMap[id] = resp.data.first_name;
                })
                .catch((err) => {});
        },

        async saveField() {
            let payload = {};
            payload.label = this.fieldLabel;
            payload.field_type = this.fieldType;
            payload.description = this.fieldDescription;

            if (this.dynamicOptions.length > 0) {
                payload.options = this.dynamicOptions;
            }

            let response = caseApi
                .post("/api/v1/fields/", payload)
                .then((resp) => {
                    this.$refs.modalName.closeModal();
                    Promise.all([this.fetchFields()]);
                })
                .catch((err) => {
                    if (err.response && err.response.status == 400 && err.response.data.detail === "Label can not be empty") {
                        this.submissionError = true;
                    }
                });
        },

        async fetchFieldTypes() {
            let fieldsTypes = await caseApi.get("/api/v1/fields/types");
            this.fieldSelectOptions = fieldsTypes.data;
        },

        async fetchFields() {
            let response = caseApi.get("/api/v1/fields").then((response) => {
                this.fields = response.data;
                this.filteredFields = response.data;
                this.paginate(1);
            });
        },

        paginate(page_number) {
            this.currentFieldPage = page_number;
            this.filteredFields = this.fields.slice((page_number - 1) * this.fieldsInonePage, page_number * this.fieldsInonePage);
        },

        showPaginationNavigation() {
            //extra ternary values are not required logic ally but makes intent clear.
            return this.fields.length < this.fieldsInonePage ? false : true;
        },
    },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-container {
    width: 300px;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: var(--brand-font);
}

.modal-header h3 {
    margin-top: 0;
    color: #42b983;
}

.modal-body {
    margin: 20px 0;
}

.modal-default-button {
    float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.save__field__button {
    float: right;
}

.options__value__wrapper {
    display: flex;
    width: 70%;
    margin: 0 auto;
    justify-content: space-around;
}
.options__wrapper {
    background: #f7f7f7;
    border-radius: 10px;
    padding: 20px;
    border-left: solid 2px blue;
}

.add__new__option {
    background: red;
}

.table {
    margin: 0 auto;
    background: white;
    padding: 50px;
    width: 80%;
    margin-top: 100px;
}

table {
    margin: 0 auto;
    background: white;
    padding: 50px;
    width: 100%;
}

.field__wrapper {
    display: flex;
    margin-bottom: 2 0px;
    padding: 5px;
    margin-top: 2px;
    border-radius: 4px;
}

.field__label {
    /* background: red; */
    width: 280px;
    /* padding-left: 20px; */
    margin-top: 7px;
    color: #131386;
}

.field__description {
    color: #353a49;
    font-size: 0.9em;
}

.field__type {
    margin-top: 7px;
    width: 280px;
}
.add__custom__field {
    margin-bottom: 40px;
}

.field__type__chooser .vs__dropdown-toggle {
    height: 60px;
}

.modal > div.modal__dialog {
    width: 30%;
}

.field__type--typename {
    margin-left: 10px;
}

.field__headers {
    display: flex;
    margin-bottom: 20px;
    border-bottom: solid 1px #e9e9e9;
    color: black;
}

.pagination {
    margin-top: 50px;
    border-top: solid 1px #e7e7e7;
}

.paginator__link {
    margin-top: 4px;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 2px;
    margin-right: 2px;
    color: white;
    text-decoration: none;
    display: inline-block;
}

.highlightPaginateNavigation {
    background: #385048;
    padding-left: 6px;
    padding-right: 6px;
    color: white;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 1px;
}
</style>
